// from : https://github.com/Yaska/redux-mediaquery/blob/master/src/media-query-tracker.js

const listeners = []
export const MEDIA_CHANGED = '@@rdx-mqt/MEDIA_CHANGED'

// Reducer
export function reducer(state = {}, action) {
  switch (action.type) {
  case MEDIA_CHANGED:
    return { ...state, ...action.data}
  default:
    return state
  }
}

// Actions
export function mediaChanged(data) {
  return {
    type: MEDIA_CHANGED,
    data
  }
}

const getSizes = (data) => {
  return {
    innerWidth: global.innerWidth,
    innerHeight: global.innerHeight
  }
}

// from : https://davidwalsh.name/javascript-debounce-function
// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
function debounce(func, wait, immediate) {
  let timeout;
  return function() {
    const context = this; const args = arguments;
    const later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

const makeOnResize = dispatch => () => {
  const data = {}
  dispatch(mediaChanged(getSizes(data)))
}

function trackMediaQuery(label, query, dispatch, initData, debounceTime = 300) {
  const mq = global.matchMedia(query)

  const listener = () => {
    dispatch(mediaChanged({
		  [label]: mq.matches
	  }))
  }

  const listenerDebounced= debounce(listener, debounceTime);
  mq.addListener(listenerDebounced)
  initData[label] = mq.matches
	
}

export function mediaQueryTracker(options, dispatch) {
  console.log('mediaQueryTracker options: ', options)
  const initData = {
    windowDimensions: {
      innerHeight: 0,
    }
  }
  if (global.matchMedia) {
    const onRe = debounce(makeOnResize(dispatch), options.debounceTime || 300 );
    if (global.addEventListener) {
				
    }
    if(options.windowDimensions && options.windowDimensions.doTrack === true){
      global.addEventListener('resize', onRe, true);
    }
    if(options.windowDimensions.innerHeight === 0){
      console.log('trigger resize bedause hieght is 0')
      if(window){
        window.dispatchEvent(new Event('resize'));
      }
        
    }

    for (const queryObject of options.queries) {
      const label = Object.keys(queryObject)[0];
      const query = queryObject[label];
      trackMediaQuery(label, query, dispatch, initData, options.debounceTime);
    }
    dispatch(mediaChanged(initData))
  }
}
