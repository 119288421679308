// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-home-page-js": () => import("./../../src/templates/homePage.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-participant-category-page-js": () => import("./../../src/templates/participantCategoryPage.js" /* webpackChunkName: "component---src-templates-participant-category-page-js" */),
  "component---src-templates-participant-page-js": () => import("./../../src/templates/participantPage.js" /* webpackChunkName: "component---src-templates-participant-page-js" */),
  "component---src-templates-participants-all-list-js": () => import("./../../src/templates/participantsAllList.js" /* webpackChunkName: "component---src-templates-participants-all-list-js" */),
  "component---src-templates-standard-page-js": () => import("./../../src/templates/standardPage.js" /* webpackChunkName: "component---src-templates-standard-page-js" */)
}

