const GlobalStylesThemed = ( theme ) => (`
  @font-face{
    font-family: Rockwell;
    src:url("/fonts/f60b9b8c-1fb9-43fc-a776-1ed969eec0b6.woff2") format("woff2"),url("/fonts/809557e9-7c0c-4021-8ec0-d11de2761d0f.woff") format("woff");
    font-display: swap;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
  }

  @font-face{
    font-family: Rockwell;
    src:url("/fonts/a96cf9d1-9a0e-455a-82ed-91ad7a84eea7.woff2") format("woff2"),url("/fonts/64a03e10-a3d3-4484-a566-ffaa4b860428.woff") format("woff");
    font-display: swap;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
  }

  @font-face{
    font-family: SofiaW01;
    src:url("/fonts/8b31e0d7-5f53-45c2-b318-064f0b532543.woff2") format("woff2"),url("/fonts/476fe015-ab32-40a2-8fed-0a5af9a3bba4.woff") format("woff");
    font-display: swap;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
  }

  @font-face{
    font-family: SofiaW01;
    src:url("/fonts/80042803-0630-405f-b2d2-af97b4e50b46.woff2") format("woff2"),url("/fonts/ec41d681-0313-4b04-88c0-820aebb51f4e.woff") format("woff");
    font-display: swap;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
  }

  html, body {
    font-family:"Rockwell W01 Bold";
    margin: 0;
    padding: 0;
  }

  #gatsby-focus-wrapper{
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  }
  *, *::after, *::before {
    box-sizing: border-box;
  }

  body {
    background: ${theme.mi_background_real};
    color: ${theme.mi_black};
    text-rendering: optimizeLegibility;
    padding-top: 80px;


  }

  a {
    color: ${theme.mi_black};
    text-decoration: underline;

  }


  p {
    margin-top: 0;
    margin-bottom: 0;
    font-family: SofiaW01;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
    color: ${theme.mi_black};
  }
  @media(min-width: 769px){
    body{
      padding-top: 96px;
    }
    p {
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.7;
      letter-spacing: normal;
      max-width: 750px;
      margin: 0 auto;
    }
  }

  .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom{
    right: 50px !important;
  }

.misingle{
  background: ${theme.mi_white};
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transform: translateX(0);
  pointer-events: all;
  overflow-y: scroll;
  transition-delay: 100ms;
}

.misingle-enter {
  transform: translate3d(-100%,0,0);
}
.misingle-enter-active {
  transform:translate3d(0,0,0);
  transition: transform 300ms;
  transition-delay: 700ms;
}
.misingle-exit {
  transform:translate3d(0,0,0);
}
.misingle-exit-active {
  transform:translate3d(-100%,0,0);
  transition: transform 200ms;
  transition-delay: 100ms;
}

.misingle-enter-done{
  pointer-events: all;
}

@media(min-width:768px){
  .misingle{
    max-width: 50vw;
  }
  .misingle-enter-active {
    transition: transform 800ms;
    transition-delay: 100ms;
  }
  .misingle-exit-active {
    transition: transform 200ms;
    transition-delay: 100ms;
  }
}

.mi-tooltip{
    width: auto !important;
    height: 32px !important;
    background: ${theme.mi_black} !important;
    border-radius: 4px !important;

    color: ${theme.mi_white} !important;
    font-family: SofiaW01 !important;
    font-size: 13px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: 0.9px !important;
    text-align: center !important;

    display: flex !important;
    align-self: center !important;
    justify-content: center !important;

    padding: 5px 8px 9px 11px !important;
    margin-top: 23px !important;

    &.place-bottom {

      &:after {
        border: none !important;
      }
      &:before{
        border: none !important;
      }
    }


}
`)

export default GlobalStylesThemed
