/* eslint-disable camelcase */
import { combineReducers } from 'redux';
import {reducer as responsive, mediaQueryTracker} from '../../utils/media-query-tracker';
import category_filter from './all/categoryFilter';
import count from './all/count';
import isStateInitialized from './all/initialized';
import map from './all/map';
import maps_api_key from './all/mapsApiKey';
import participants from './all/participants';
import route from './all/route';
import view from './all/view';

const rootReducer = combineReducers({
  isStateInitialized, 
  map,
  participants,
  count,
  maps_api_key,
  category_filter,
  responsive,
  view,
  route,
});

export { rootReducer as default }


