import React, { useState, useRef } from 'react';
import { css, Global } from '@emotion/core'
import styled from '@emotion/styled'
import { withTheme, ThemeProvider } from 'emotion-theming'
import PropTypes from "prop-types"
// import FocusLock from 'react-focus-lock';
import { connect } from "react-redux"
import { bindActionCreators } from  'redux';
import FilterCategories from '../components/FilterCategories'
import FilterCategoriesMobile from '../components/FilterCategoriesMobile'
import Footer from '../components/Footer'

import HeaderBackDrop from '../components/header/HeaderBackDrop'
import HomeLink from '../components/header/HomeLink'
import MapLink from '../components/header/MapLink'
import LogoWall from '../components/LogoWall';
import { MoabitMapWrapper } from '../components/map/MoabitMapWrapper';
import { MoabitMapWrapperMobile } from '../components/map/MoabitMapWrapperMobile';

// import Burger from '../components/navigation/burger'
import BurgerAll from '../components/navigation/BurgerAll'
import MainMenu from '../components/navigation/mainmenu'
import ParticipantCards from '../components/participants/ParticipantCards';
import ParticipantCardsMobile from '../components/participants/ParticipantCardsMobile';


import ParticipantSingle from '../components/participants/ParticipantSingle'

// import HamburgerMenu from '../components/navigation/menu'
import *  as actionCreators from '../state/actions';
// import { useOnClickOutside } from './hooks';
import GlobalStylesThemed from './utils/global';
import theme  from './utils/theme';

const makeGlobalStyles = t => {
  const cssStr = GlobalStylesThemed(t)
  return css`${cssStr}`
} 

const GlobalStyles = withTheme(({ theme }) => (
  <Global styles={makeGlobalStyles(theme)} />
))
const Counter = (props) => {
  const { count, increment } = props;

  // console.log('props are: ', props);

  return (
    <div>
      <pre />
      <p>
        Countr:
        {count}
      </p>
      <button type="submit" onClick={increment}>Increment</button>
    </div>
  )}

Counter.propTypes = {
  count: PropTypes.number.isRequired,
  increment: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    map_opt_in_confirmed: state.map.opt_in.confirmed,
    map_opt_in_persisted: state.map.opt_in.persisted,
    count: state.count,
    isMapView: state.map.isMapView,
    isCategoryView: state.view.isCategoryView,
    participantData : state.participants.participantData
  }
}

const mapDispatchToProps = (dispatch) => {
  const as = {
    ...actionCreators,
  }
  return bindActionCreators(as, dispatch);
}

const ConnectedCounter = connect(mapStateToProps, mapDispatchToProps)(Counter)

const MapLayout = ( props ) => {
  // console.log('MapLayout props: ', props);
  const { pathName, places, isMapView, isCategoryView, children } = props;

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <header id="mi-header">
        <HeaderBackDrop>
          <HomeLink />
          <MapLink />
          <BurgerAll />
          <MainMenu />
        </HeaderBackDrop>
      </header>
      <Main>
        {/* {isMapView && !isCategoryView ? children : null } */}
        {isMapView? (
          <>
            <WrapMapView>
              <MoabitMapWrapperMobile places={places} {...props}  />
              <FilterCategoriesMobile />
              <ParticipantCardsMobile places={places} />
            </WrapMapView>
            <ParticipantSingle places={places} {...props} />
          </>
        )
          :null}
        {/* {isMapView ? <MoabitMapWrapperMobile places={places} {...props}  /> : null }
        {isMapView ? <FilterCategoriesMobile /> : null }
        {isMapView ? <ParticipantCardsMobile places={places} /> : null } */}
        {/* {isCategoryView ? <FilterCategories /> : null } */}
        {/* {isCategoryView ? <ParticipantCards places={places} /> : null } */}

        {children}
        {/* <ParticipantCards /> */}
        {/* {isMapView ?<MoabitMapWrapper places={places} {...props} /> :  null } */}
      </Main>
      <LogoWall />
      <Footer />
    </ThemeProvider>
  )}

const Main= styled.main`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  position: relative;
`;


const WrapMapView= styled.div`
  // display: flex;
  // flex-direction: column;
  // height: calc(100vh - 80px);
  // height: calc(var(--vh, 1vh) * 100 - 80px);

  @media(min-width: 768px){    
    display: flex;
  }

`;


const ConnectedMapLayout = connect(mapStateToProps, mapDispatchToProps)(MapLayout)

export default ConnectedMapLayout
