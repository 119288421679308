import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import rootReducer from './reducers'
import thunkMiddleware from 'redux-thunk'
// const asyncDispatchMiddleware = store => next => action => {
//   let syncActivityFinished = false;
//   let actionQueue = [];

//   function flushQueue() {
//     actionQueue.forEach(a => store.dispatch(a)); // flush queue
//     actionQueue = [];
//   }

//   function asyncDispatch(asyncAction) {
//     actionQueue = actionQueue.concat([asyncAction]);

//     if (syncActivityFinished) {
//       flushQueue();
//     }
//   }

//   const actionWithAsyncDispatch =
//       { ...action, asyncDispatch};

//   next(actionWithAsyncDispatch);
//   syncActivityFinished = true;
//   flushQueue();
// };
// import { createStore as reduxCreateStore } from "redux"

// const reducer = (state, action) => {
//   if (action.type === `INCREMENT`) {
//     return Object.assign({}, state, {
//       count: state.count + 1,
//     })
//   }
//   return state
// }

// const initialState = { count: 0 }

// const createStore = () => reduxCreateStore(reducer, initialState)
// export default createStore
const initialState = {
  isStateInitialized: false,
  map: {
    isMapView: false,
    opt_in: {
      confirmed: typeof document !== 'undefined' 
        ? document.cookie.indexOf('mi_allow_gmaps=') > -1
        : false,
      // user confirmed map usage
      persisted : typeof document !== 'undefined' 
        ? document.cookie.indexOf('mi_allow_gmaps=') > -1
        : false, // confirmation is saved as cookie
      count: 93, // for test pruposes only
    },
  },
  participants: {
    active_category: {
      id: null,
      name: '',
      slug: ''
    },
    active_participant_id: null,
    activated_participant_id: null,
    opened_participant_id: null,
    activated_participant_offset_x: 0,
    participants: [],
    participantData: null
  },
  // mapOptIn: {
  //   map_count: 111,
  //   maps_allowed: typeof document !== 'undefined' 
  //     ? document.cookie.indexOf('mi_allow_gmaps=') > -1
  //     : false,
  //   maps_allowed_with_cookie: typeof document !== 'undefined' 
  //     ? document.cookie.indexOf('mi_allow_gmaps=') > -1
  //     : false,
  // },
  count: 2,
  // maps_allowed : typeof document !== 'undefined' 
  //   ? document.cookie.indexOf('mi_allow_gmaps=') > -1
  //   : false,
  maps_api_key: process.env.REACT_APP_MAP_KEY,
  category_filter: 'all',

  // gmaps: {
  //   loaded: false,
  //   map: null,
  //   google: null,
  //   zoom: 4
  // },
}

function configureStore(preloadedState) {
  const middlewares = [thunkMiddleware]
  const middlewareEnhancer = applyMiddleware(...middlewares)

  const enhancers = [middlewareEnhancer]
  const composedEnhancers = composeWithDevTools(...enhancers)

  const store = createStore(rootReducer, preloadedState, composedEnhancers)

  return store
}

const store = configureStore( initialState )



export default store;