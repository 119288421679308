import React, { useState, useEffect } from "react";
import styled from '@emotion/styled'
import { globalHistory } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"
import { connect } from "react-redux";
// import slugify from '../../utils/slugify'
import { filterParticipants, setIsMapView, setIsCategoryView, setActiveParticipant, activateParticipant, setActivatedParticipantOffsetX, updateRoute } from '../../state/actions'
import ArtsAndCrafts from '../FilterCategoriesButtons/ArtsAndCrafts'
import Business from '../FilterCategoriesButtons/Business'
import Gastronomy from '../FilterCategoriesButtons/Gastronomy'
import Social from '../FilterCategoriesButtons/Social'
import All from '../FilterCategoriesButtons/All'
import Link from '../Link'
import ReactTooltip from 'react-tooltip';

const FilterCategories = (props) => {



  const datas = useStaticQuery(graphql`
  query {
    allWpParticipantCategory {
        nodes {
          id
          name
          databaseId
          uri
          nodeType
        }
    }
    allWpTaxonomy( filter: { name: { eq: "moabit_participant_category" }} ) 
    {
      nodes {
        archivePath
        name
        nodeType
        restBase
      }
    }
  }
`)

  const data = [...datas.allWpTaxonomy.nodes, ...datas.allWpParticipantCategory.nodes ]


  const { 
    filter_category_id, 
    filter_category_name, 
    filterParticipants ,
    isMapView,
    setIsMapView,
    setIsCategoryView,
    setActiveParticipant, activateParticipant, participants, setActivatedParticipantOffsetX, updateRoute
  } = props
  // console.log('SETIS_MAPIFJDLS: ', setIsMapView)
  const triggerAction = (cat) => {
    setIsMapView(true)
    setIsCategoryView(true)
    const newLocation = {
      pathname: cat? cat.uri : '/erkunden/'
    }
    updateRoute(newLocation)

    if(cat){
      // console.log('participants: ', participants)
      // console.log('cat.databaseId: ', cat.databaseId)

      console.log('Filter categories, cat: ', cat)
      filterParticipants(cat.databaseId, cat.name, cat.uri)
      const firstOfCatId = participants.find(p => p.categoryId === cat.databaseId).id


      setActiveParticipant(firstOfCatId)
      activateParticipant(firstOfCatId)
      setActivatedParticipantOffsetX(24);
    } else {
      const firstId = participants[0].id
      setActiveParticipant(firstId)
      activateParticipant(firstId)
      setActivatedParticipantOffsetX(24);

      filterParticipants(null)
    }
  }

  // const toIgnore = [
  //   'Weekly Rundown',
  //   'Design',
  //   'Inspiration',
  //   'Uncategorised',
  //   'UX',
  // ]

  const Wrapper = styled.section`
  width: 100vw;
  height: 60px;
  height: 88px;
  border-top: solid 2px ${({ theme }) => theme.mi_white};
  border-bottom: solid 2px ${({ theme }) => theme.mi_white};
  background-color: ${({ theme }) => theme.mi_background_real};
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;

  flex: 1 0 100vw;
  order: 2;
  z-index: 8;
  flex-wrap: wrap;
  @media(min-width: 768px){
    order: 3;

    /* display: none !important; */
    /*width: 50vw;
    height: calc(100vh - 95px);
    height: calc(var(--vh, 1vh) * 100 - 95px);*/
    position: fixed;
    top: 0;
    left: 42px;
    width: auto;
    z-index: 16;
    display: flex;
    align-items: center;
    height: 96px;
    box-shadow: none;
    padding-left: 0;

    border: none;
  }
`;

  const FilterBar = styled.ul`
  flex: 1 0 100vw;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;

  position: relative;
  top: -2px;

  @media(min-width: 768px){
    flex: 1 0 auto;
    top: initial;
  }
`;

  const FilterItem = styled.li`
  width: 48px;
  height: 48px;

  margin-right: 15px;
  overflow: hidden;
  &:last-of-type{
    margin-right: 0;
  }

  &.active{

  }
  a {
    display: block;
    width: 100%;
    height: 100%;
  }
  @media(min-width: 768px){
    height: 60px;
    width: 60px;
  }
`;

const FilterBarCurrentLabel = styled.div`
flex: 1 0 100vw;
width: 100vw;
height: 24px;
box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
border: solid 2px  ${({ theme }) => theme.mi_white};
background-color:  ${({ theme }) => theme.mi_white};

font-family: SofiaW01;
font-size: 12px;
font-weight: bold;
line-height: 1.88;
letter-spacing: 0.5px;
margin-bottom: auto;
padding-left: 30px;
display: flex;
align-items: center;
@media(min-width: 768px){
  display: none;
}

`

  

  return (
    <Wrapper>
      <FilterBarCurrentLabel>
        {filter_category_id? filter_category_name : 'Alle Kategorien'}
      </FilterBarCurrentLabel>
      <FilterBar>
        {data.map((cat) => {
          const [inHover, setHover] = useState(false);

          if (cat.nodeType === 'ParticipantCategory') {
            const isActive = filter_category_id === cat.databaseId;

            const id = cat.databaseId;
            return (
              <FilterItem 
                data-tip={cat.name}
                key={cat.id}
                onClick={() => triggerAction(cat)}
                onMouseEnter={() => {
                  console.log('setHover to true')
                  setHover(true)}}
                onMouseLeave={() => {
                  console.log('setHover to false')
                  setHover(false)}}
              >
                <Link to={cat.uri}>
                  {id === 9 ? <ArtsAndCrafts active={isActive}  /> : null }
                  {id === 7 ? <Social active={isActive}  /> : null }
                  {id === 5 ? <Business active={isActive}  /> : null }
                  {id === 8 ? <Gastronomy active={isActive}  /> : null }
                </Link>
                {/* <Link
                  key={`link_${cat.id}`}
                  to={cat.uri}
                  onClick={() => triggerAction(cat)}
                  activeClassName="active"
                > */}
                {/* {id === 9 ? <ArtsAndCrafts active={isActive}  /> : null } */}
                {/* </Link> */}
                
              </FilterItem>
            )
          }
          if(cat.nodeType === 'Taxonomy') {
            const isActive = filter_category_id === null;
            return (
              <FilterItem 
                className={isActive? 'active' : ''} 
                key={cat.archivePath}
                data-tip="Alle Kategorien"
              >
                <Link
                  key={`link_${cat.archivePath}`}
                  to={cat.archivePath}
                  onClick={() => triggerAction(null)}
                >
                  <All active={isActive}  />
                </Link>
              </FilterItem>
            )
          }
          return false
        })}
      </FilterBar>
      <ReactTooltip place="bottom" effect="solid" className="mi-tooltip" />
    </Wrapper>
  )
}

// const FilterCategories = ( props ) => {
//   const { filter_category_id, filter_category_name, filterParticipants, setIsMapView, setIsCategoryView } = props;
//   // console.log('FilterCategories props', props)
//   return (
//     <StaticQuery
//       query={}

//     />
//   )}

const mapStateToProps = state => {
  // console.log('mapStateToProps FilterCategories: ', state)
  return {
    filter_category_id: state.participants.active_category.id,
    filter_category_name: state.participants.active_category.name,
    isMapView: state.map.isMapView,
    isCategoryView: state.view.isCategoryView,
    participants: state.participants.participants,
  }
};


// export default FilterCategories
const mapDispatchToProps = {
  filterParticipants,
  setIsMapView,
  setIsCategoryView,
  setActiveParticipant, 
  activateParticipant,
  setActivatedParticipantOffsetX,
  updateRoute,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterCategories);