/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import { globalHistory } from "@reach/router"

import { updateRoute, setIsMapView, setIsCategoryView } from './src/state/actions'
import store from './src/state/createStore';
import { mediaQueryTracker, mediaChanged} from './src/utils/media-query-tracker';
import wrapWithProvider from "./wrap-with-provider"

const wrapRootElement = wrapWithProvider;

globalHistory.listen(({ action, location }) => {
  if (action !== 'PUSH') {
    console.log('\n\n\n action !== PUSH')
    console.log(JSON.stringify(location, null, 4))
    store.dispatch(updateRoute(location))
    const firstPathSegment = location.pathname.split('/')[1]
    console.log('firstPathSegment', firstPathSegment)
    if( firstPathSegment && firstPathSegment === 'erkunden') {
      store.dispatch(setIsMapView(true))
      store.dispatch( setIsCategoryView(true))
    }
  } else {
    console.log('action ?== PUSH')
  }
})

function appHeight() {
  const doc = document.documentElement
  doc.style.setProperty('--vh', `${window.innerHeight*.01  }px`);
}

window.addEventListener('resize', appHeight);
appHeight();

window.addEventListener("load", function load(event) {

  // store.dispatch(mediaChanged())
  // store.dispatch(mediaQueryTracker({
  //   isPhone: "screen and (max-width: 767px)",
  //   innerWidth: true,
  //   innerHeight: true,
  // }))
  // mediaQueryTracker({
  //   debounceTime: 15000,
  //   queries: [
  //     {isPhone : "screen and (max-width: 767px)"},
  //     {isTablet: "screen and (min-width: 768px) and (max-width: 1024px)"},
  //     {isDesktop: "screen and (min-width: 1025px)"}
  //   ],
  //   windowDimensions: {
  //     doTrack: true,
  //     innerHeight: 0,
  //     innerWidth: 0
  //   }
  // }, store.dispatch );

}, false);


export { wrapRootElement }
